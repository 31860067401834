import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { AboutIndexQuery } from "../../graphql-types"
import Heading from "../assets/elements/heading"
import { List } from "../assets/elements/list"
import Paragraph from "../assets/elements/paragraph"
import Email from "../assets/images/email.svg"
import Github from "../assets/images/github.svg"
import Twitter from "../assets/images/twitter.svg"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { theme } from "../utils/theme"
import { PageTitle } from "../components/PageTitle"

interface AboutIndexProps {
  data: AboutIndexQuery
  location: Location
}

const StyledLink = styled.a`
  color: var(--text);
  svg {
    margin-right: ${theme.spacing}px;
    fill: var(--accent);
  }
`

const AboutIndex: React.FC<AboutIndexProps> = props => {
  const { data } = props
  const siteTitle = data.site?.siteMetadata?.title || ""

  const socialLinks = [
    <StyledLink
      key="twitter"
      href={
        "https://www.twitter.com/" + data.site?.siteMetadata?.social?.twitter
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      <Twitter /> Twitter
    </StyledLink>,
    <StyledLink
      key="github"
      href={"https://www.github.com/" + data.site?.siteMetadata?.social?.github}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Github /> Github
    </StyledLink>,
    <StyledLink
      key="email"
      href={"mailto: amberbrookhuis@gmail.com"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Email /> Email
    </StyledLink>,
  ]

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="About" />
      <PageTitle>Hey, it&apos;s Amber</PageTitle>
      <Paragraph>
        I’m a frontend developer based in Rotterdam, The Netherlands. I enjoy
        creating beautiful and performant web applications, providing a
        meaningful experience. Besides writing code I am also into design and
        UX.
      </Paragraph>
      <Paragraph>
        A few technologies I&apos;ve been working with recently:
        <List marker={"▸"}>
          <>React</>
          <>TypeScript / JavaScript</>
          <>Node.js</>
          <>HTML & (S)CSS</>
        </List>
      </Paragraph>
      <Paragraph margin={3}>
        Next to building things for the web, I am a huge fan of electronic music
        and going to raves. Because of the pandemic I started gaming, became a
        home DJ –who mostly plays techno and started learning to play the
        guitar.
      </Paragraph>
      <Heading size={3} margin={2}>
        Get in touch
      </Heading>
      <List spacing={theme.spacing}>{socialLinks}</List>
    </Layout>
  )
}

export default AboutIndex

export const pageQuery = graphql`
  query AboutIndex {
    site {
      siteMetadata {
        title
        author
        social {
          twitter
          instagram
          github
        }
      }
    }
  }
`
