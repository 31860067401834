import * as React from "react"
import styled from "styled-components"
import { styledMap } from "./_styled-map"
import { MarginSpaces, MarginSpacesMap } from "./_types"

const margins: MarginSpacesMap<string> = {
  0: "0",
  1: "0.5rem",
  2: "1rem",
  3: "2rem",
  4: "3rem",
  5: "6rem",
}

interface ParagraphProps {
  children: React.ReactNode
  margin?: MarginSpaces
}

const StyledParagraph = styled.p<ParagraphProps>`
  margin-bottom: ${styledMap(
    props => (props.margin ? props.margin : 0),
    margins
  )};
`

const Paragraph: React.FC<ParagraphProps> = props => {
  return (
    <StyledParagraph margin={props.margin}>{props.children}</StyledParagraph>
  )
}

Paragraph.defaultProps = {
  margin: 2,
}

export default Paragraph
